.footer-span{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    margin-top: 2rem;
    border-radius: 75.556px;
    color: var(--indigo-700, #4338CA);
    background: var(--indigo-50, #EEF2FF);
    margin: 2rem;
    font-family: Manrope;
}

.footer-main{
 display: flex;
 margin: 1.5rem;
 margin-top: 4rem;
 margin-left: 2rem;
}

.footer-s1{
width: 28%;
margin-left: 2rem;
}
.footer-s1 div{
    margin-bottom: 1rem;
}
.footer-s1 .icons{
    margin-left: 2rem;
    margin-top: 1rem;
}

.footer-s2{
width: 15%;
margin-left: 2rem;
}

.footer-s2 h4{
    font-family: Space Grotesk;
}
.footer-s2 a{
    width: 15%;
    display: flex;
    margin-top: 0.5rem;
    flex-direction: column;
    text-decoration: none;
    color: #737373;
    font-family: Manrope;
}