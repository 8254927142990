.header-main{
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
    justify-content: space-between;
    height: 5rem;
}

.header-s1{
    display: flex;
    align-items: center;

}

.header-s1 img{
    width: 40px;
    height: 41px;
}
.header-s1 span{
    color: var(--heading, #333);
    font-family: 'Clash Grotesk', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    margin-left: 5px;
    line-height: 150%;
}
.header-s1 img{
    display: flex;
    align-items: center;

}
.header-s2{
    display: flex;
    justify-content: space-between;
   
}
.header-s2 a{
    margin-left: 0.5rem;
    text-decoration: none;
    color: #333333CC;
    margin-left: 3vw;
    font-family: Manrope;
}

.header-s2 a:active{
    color: #501CB7;
}

.header-s2 ul li{
    margin-left: 1rem;
}

.header-s2 ul li{
    margin-left: 1rem;
}

.btn-1{
    border: none;
    color: #6016C6;
    margin-right: 2rem;
    font-family: Manrope;
}
.btn-2{
    height: 2.5rem;
    width: 8vw;
    margin-left: 1rem;
    border-radius: 40px;
    color: #6016C6;
    border: 1.5px solid #6016C6;
    margin-right: 1rem;
    font-family: Manrope;
}


.nav-link {
    text-decoration: none;
    cursor: pointer;
    
    transition: color 0.5s ease-in-out; 
} 
   .header-main{
    display: flex;
    align-items: center;
    margin-left: 2rem;
    margin-right: 2rem;
    justify-content: space-between;
    height: 5rem;
}

.header-s1{
    display: flex;
    align-items: center;

}

.header-s1 img{
    width: 40px;
    height: 41px;
}
.header-s1 span{
    color: var(--heading, #333);
    font-family: 'Clash Grotesk', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    margin-left: 5px;
    line-height: 150%;
}
.header-s1 img{
    display: flex;
    align-items: center;

}
.header-s2{
    display: flex;
    justify-content: space-between;
   
}
.header-s2 a{
    margin-left: 0.5rem;
    text-decoration: none;
    color: #333333CC;
    margin-left: 3vw;
    font-family: Manrope;
}

.header-s2 a:active{
    color: #501CB7;
}

.header-s2 ul li{
    margin-left: 1rem;
}

.header-s2 ul li{
    margin-left: 1rem;
}


.btn-2{
    height: 2.5rem;
    width: 8vw;
    margin-left: 1rem;
    border-radius: 40px;
    color: #6016C6;
    border: 1.5px solid #6016C6;
    margin-right: 1rem;
    font-family: Manrope;
    cursor: pointer;
}
.login{
    border: none;
    color: #6016C6;
    margin-right: 2rem;
    font-family: Manrope;
    cursor: pointer;
}