.courseschedule-main{
    margin: 2rem;
}

.courseschedule-main h2{
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Sora;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
}

.schedule-hr{
   width: 90vw;
   margin-left: 0;
}
