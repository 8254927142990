.coursedetails-main{
 margin: 2rem;
}

.coursedetails-1{
 margin-top: 2rem;
}

.coursedetails-1 h2{
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: "sora";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 64px;
}

.coursedetails-1 p{
    color: #666;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.coursedetails-1 ol li{
 margin-left: 2rem;
 color: #666;
font-family: Manrope;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 30px;
}
