.featured-main{
    margin: 2rem;
}

.featured-heading{
    display: flex;
}

.featured-heading h1{
    margin-left: 1rem;
}

.featured-button{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
}

.featured-button p{
   width: 75%;
}

.featured-btn{
    width: 7rem;
    height: 3rem;
    margin-left: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 32px;
    background-color: var(--blue, #501CB7);
}
.featured-btn button{
    border: none;
    color: white;
    background-color: var(--blue, #501CB7);
}

.featured-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}