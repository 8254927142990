.coursetutor-heading h3{
    color: #333;
font-size: 2rem;
font-weight: 700;
line-height: 64px;
font-family: "sora";
}

.coursetutor-main{
    margin: 2rem;
}

.coursetutor-plus{
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8vh;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 10px;
border: 1px solid #E7EBEE;
background-color: #F5F5F5;
}
.coursetutor-plus p{
    background-color: #F5F5F5;
    font-weight: 700;
    color: var(--black, #262626);
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 130%;
}
.coursetutor-plus img{
    width: 32px;
height: 32px;
flex-shrink: 0;
background-color: #F5F5F5;
}

.coursetutor-minusp{
    color: #575757;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.coursetutor-minus{
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
    background-color: #FFF;
stroke-width: 1px;
border: 1px solid #501CB7;
border-radius: 25px;
padding: 1rem 1rem;
filter: drop-shadow(0px 20px 95px rgba(201, 203, 204, 0.30));
}

.coursetutor-p{
    color: var(--blue, #501CB7);
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 130%;
margin-bottom: 1rem;
}




.coursetutor-minus img{
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    margin-top: 1rem;
}
