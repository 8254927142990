.courseabout-main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
    border-radius: 64px;
    width: 95vw;
    background-color: #f3f6ff;
  }
  .courseabout-main::before {
    content: url(../../assets/images/cbn.png);
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .courseabout-main::after {
    content: url(../../assets//images/Ellipse\ 70.png);
    position: absolute;
    right: 419px;
    bottom: 145px;
  }
  .hide-overflow {
    overflow: hidden;
    position: relative;
  }

  .text-containera {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    margin-left: 57px;
    background: transparent;
    height: 60vh;
  }
  .heading {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    font-family: "Manrope", sans-serif;
    text-align: left;
    margin-bottom: 8px;
    background: transparent;
  }
  .tagline {
    flex-shrink: 1;
    font-size: 56px;
    font-weight: 600;
    line-height: 69px;
    letter-spacing: 0em;
    /* width: 630px; */
    text-align: left;
    margin-bottom: 16px;
    margin-top: 0px;
    font-family: "Sora", sans-serif;
    color: #4338ca;
    background: transparent;
  }
  .tagline2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
    width: 630px;
    text-align: left;
    margin-top: 0px;
    font-family: "Sora", sans-serif;
    background: transparent;
  }
  .image-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: 603px;
    margin-right: 4rem;
    border-radius: 2%;
    overflow: hidden;
  }
  .courseabout-main button{
    width: 178px;
    height: 64px;
    padding: 18px 32px 18px 32px;
    border-radius: 40px;
    padding: 10px;
    border: none;
    color: white;
    background-color: #501cb7;
    font-family: "Manrope", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 2rem;
    cursor: pointer; 
    transition: background-color 0.3s; 
  }


.ca-d{
    background: transparent
}

.image-container {
  width: 50%;
  padding: 20px; 
  box-sizing: border-box;
  margin-bottom: 0;
}

.review-section {
  display: flex;
  flex-direction: column;
}

.review-item {
  display: flex;
  align-items: flex-start;
  /* margin-bottom: 20px;  */
}

.avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px; 
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-content {
  flex: 1;
}

.username {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
}

.user-role {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.user-description {
  font-size: 14px;
  line-height: 1.4;
  color: #333;
}

















.review-section {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.review-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.review-item:hover {
  transform: translateY(-5px);
}

.avatar {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-content {
  flex: 1;
}

.username {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333; 
}

.user-role {
  font-size: 14px;
  color: #888;
  margin-bottom: 10px;
}

.user-description {
  font-size: 14px;
  line-height: 1.4;
  color: #555;
}

.reviews-container {
  margin-top: 2rem;
  padding: 20px;
  background-color: #f9f9f9; 
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Style the overall rating */
.rating {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
  color: #501cb7;
}

.read-all-reviews {
  text-align: center;
  margin-top: 20px;
}

.read-all-reviews a {
  color: #501cb7; 
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease-in-out;
}

.read-all-reviews a:hover {
  color: #4338ca;
}
