.b2-main{
margin: 3rem;
display: flex;
}

.b2-img img{
    width: 40vw;
    height: 60vh;
    border-radius: 25px;
}

.b2-content{
    margin-left: 2rem;
    width: 50vw;
height: 60vh;
}
.b2-content p{
    margin-top: 1rem;
    color: #666;
    font-family: Manrope;
    font-size: 2vh;
    font-style: normal;
    font-weight: 400;
}

.b2-c1{
    display: flex;
    justify-content: space-between;
}

.b2-c1 span{
   font-size: 3rem;
}

.b2-c1 h1{
    margin-top: 2rem;
    color: #333;
font-variant-numeric: lining-nums proportional-nums;
font-family: Sora;
font-size: 4vh;
font-style: normal;
font-weight: 700;
}

.b2-content button{
    margin-top: 2rem;
    border: none;
    border-radius: 40px;
    color: #FFF;
    height: 3rem;
    width: 7rem;
background-color: var(--solid-purple, #501CB7);
box-shadow: 10px 20px 60px 0px rgba(125, 10, 224, 0.26);
}