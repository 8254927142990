*{
  margin: 0;
  padding: 0;
  background: #FFF;
  font-family: 'Clash Grotesk', sans-serif;
}

hr{
  border-radius: 4px;
background: rgba(23, 23, 23, 0.10);
}