
.login-container {
  display: flex;
  justify-content: center;
 padding: 100px;
  height: 60vh; 
  margin-right: 100px;
}

.error {
  color: red;
}

.input-group {
  margin-bottom: 15px;
  text-align: left; 
  padding-right: 21px;
}

label {
  display: block;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

button.login {
  background-color: #0074d9; 
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

button.login:hover {
  background-color: #0056b3; 
}
form{
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
 justify-content: center;
  width: 100%;
  text-align: center;
  padding: 50px;
 
  margin-bottom: 60px;
}
