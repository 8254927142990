.cards-main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2rem;
  }

  .featured-button p{
    color: var(--neutral-500, #737373);
font-family: Manrope;
  }
  .primary-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 46px;
  }

  .primary-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 25px;
    border-radius: 24px;
  }

  .primary-card-img {
    width: 100%;
    display: flex;
    height: 242px;
    flex: 1;
    margin-bottom: 30px;
  }

  .primary-card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }


  .primary-card1-utils {
    /* background-color: rgba(80, 28, 183, 1); */
    background-color: rgba(245, 245, 245, 1);
    margin-right: 47px;
    height: 70vh;
  }

  .primary-card2-utils {
    background-color: rgba(245, 245, 245, 1);
    height: 70vh;
  }

  .secondary-cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  
  }
  .secondary-card {
    display: flex;
    height: 658px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 35px;
    border-radius: 24px;
    background-color: rgba(245, 245, 245, 1);
    margin-right: 46px;
  }
  .secondary-card:nth-child(3) {
    margin-right: 0px;
  }

  .secondary-card-img {
    width: 100%;
    display: flex;
    height: 242px;
    flex: 1;
    padding-right: 24px;
    margin-bottom: 30px;
  }
  .secondary-card-img2 {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 242px;
    flex: 1;
    margin-bottom: 30px;
  }

  .secondary-card-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .secondary-card-img2 img {
    width: 70%;
    object-fit: contain;
  }

  .flexible-schedules-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
   
    width: 100%;
    flex: 1;
    border-radius: 12px;
    margin-bottom: 40px;
    background-color: white;
  }
  .flexible-schedules-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 80%;
    border-radius: 12px;
    margin-bottom: 40px;
    background-color: white;
  }

  .flexible-schedule {
    display: flex;
    align-items: center;
    width: 90%;
    margin-bottom: 10px;
    padding: 8px 18px;
    border-radius: 24px;
    justify-content: space-between;
  }

  .flexible-schedule span{
    background-color: transparent;
  }
  .flexible-schedule:nth-child(1) {
    background: rgba(224, 231, 255, 1);
  }
  .flexible-schedule:nth-child(2) {
    background: rgba(254, 249, 195, 1);
  }
  .flexible-schedule:nth-child(3) {
    background: rgba(255, 228, 230, 1);
  }
  
  .button-container {
    width: 100%;
    display: flex;
  }

  .featured-heading h1{
    color: var(--black, #262626);
font-family: "Sora";
  }
  .cards-main-container button {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding: 16px 8px 16px 8px;
    border-radius: 12px;
    background: rgba(80, 28, 183, 1);
    color: white;
    border: none;
    flex: 1;
    margin: 20px 25px;
  }
  .headingt {
    font-family: "Sora", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
    background-color: transparent;
  }

  .info {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    background-color: transparent;
  }

  .headingt1 {
    font-family: "Sora", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
    /* background: rgba(80, 28, 183, 1); */
    background-color: rgba(245, 245, 245, 1);

    color:Black;
  }

  .info1 {
    font-family: "Manrope", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    background-color: rgba(245, 245, 245, 1);

    color:Black;
  }
  .heading{
    font-family: "Sora", sans-serif;
    font-size: 32px;
    font-weight: 500;
    line-height: 39px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
    /* background: rgba(80, 28, 183, 1); */
    background-color: rgba(245, 245, 245, 1);

    color:Black;
  }











  
 