.training-main{
    height: 60vh;
    width: 95vw;
    margin-left: 2rem;
    border-radius: 25px 25px;
    background-image: url(../../assets/images/cbn.png);
    background-color: var(--white, #9818dd19);
}

.training-main h1{
    color: var(--indigo-800, #3730A3);
    text-align: center;
    background: transparent;
    font-family: Clash Grotesk , sans-serif;
    font-style: normal;
    font-weight: 600;
    padding-top: 10vh;
    margin-bottom: 7vh;
    font-family: "Sora";
}

.t3-btn-main{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    margin-bottom: 7vh;
}

.t3-btn1{
    border: none;
    border-radius: 40px;
background-color: var(--blue, #501CB7);
color: #FFF;
padding: 1vh 2vw;
font-family: Manrope;
cursor: pointer;


}

.t3-btn-main img{
    background-color: #501CB7;
    margin-left: 2vw;
    height: 3vh;
    width: 3vh;
}

.t3-btn2{
    display: flex;
    align-items: center;
    border-radius: 40px;
    border: 2px solid var(--blue, #501CB7);
    background: transparent;
    padding: 1vh 1vw;
    margin-left: 10vw;
}

.t3-btn2 button{
    background: transparent;
    color: #501CB7;
    border: none;
    margin-left: 1vw;
    font-family: Manrope;
}

.training-main p{
    background-color: transparent;
    margin: 3vw;
    color: var(--black, #262626);
text-align: center;
font-family: Manrope;
font-size: 2vw;
font-style: normal;
font-weight: 400;
line-height: normal;
}