.coursehead-main{
    display: flex;
    width: 100%;
}

hr{
    margin: 2rem;
height: 2px;
border-radius: 4px;
background: rgba(23, 23, 23, 0.10);
}

.coursehead-heading{
width: 65%;
margin: 2rem;
}
.coursehead-heading h1{
    color: var(--blue, #501CB7);
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 4rem; 
    font-family: "Sora";
}
.coursehead-heading p{
    margin-top: 3rem;
    color: #666;
font-family: Manrope;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 2rem; 
}

.coursehead-img{
    width: 40%;
    margin-right: 2rem;
    margin-top: 2rem;
}

.coursehead-img img{
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 16px;
    background:  lightgray;
}