.main-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 95vw;
    margin: 2rem;
  }
  .text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 50vw;
    margin-right: 57px;
  }
  .stats-container {
    display: flex;
    align-items: center;
  }
  .headingt1 {
    font-family: "Sora", sans-serif;
    font-size: 32px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
  }
  .taglinep {
    font-family: "Manrope", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: 0em;
    width: 630px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 3vw;
  }
  .stat-item {
    font-family: "Sora";
    margin: 1rem;
    color: #501cb7;
  }
  .stat-value {
    font-size: 42px;
    font-weight: 600;
    line-height: 72px;
    padding-right: 1vw;
    font-family: "Roboto";
  }
  .stat-label {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding-right: 1vw;
    font-family: Manrope;
  }
 

.image-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}

.icon {
  position: absolute;
  z-index: 1;
  width: 171px;
  height: 165.54405212402344px;
  top: 40%;
  left: 2px; 
  background-color: transparent;
}

.image {
 
  height: 500px;
  width: 400px;
}

