.card-main{
    height: 33rem;
    width: 44vw;
    margin: 0.7rem;
    padding: 0.7rem;
    margin-top: 4rem;
    border-radius: 24px;
    border: 0px solid rgba(0, 0, 0, 0.05);
    background-color: var(--neutral-100, #F5F5F5);
}
.card-main img{
    object-fit: cover;
    width: 100%;
}

.card-heading{
    margin-top: 1rem;
color: var(--black, #262626);
font-family: "Sora";
font-size: 23px;
font-style: normal;
font-weight: 500;
line-height: normal;
background-color: var(--neutral-100, #F5F5F5);
}

.card-text{
    margin-top: 1rem;
    background-color: var(--neutral-100, #F5F5F5);
    border-radius: 24px;
    color: var(--neutral-600, #525252);
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card-btn{
    height: 4rem;
    color: #501CB7;
    display: flex;
    align-items: center;
    background-color: var(--neutral-100, #F5F5F5);
}

.card-btn button{
    border: none;
    width: auto;
    color: #501CB7;
    margin: 0.3rem;
    background-color: var(--neutral-100, #F5F5F5);
}