.courseschedule-card{
    display: flex;
    margin-top: 2rem;
    border-radius: 20px;
    background-color: var(--neutral-100, #F5F5F5);
    height: 4rem;
    width: 93vw;
    /* padding: 15px 574px 15px 50px; */
    align-items: center;
    flex-shrink: 0;
}

.courseschedule-card h3{
    color: var(--blue, #501CB7);
    font-variant-numeric: lining-nums proportional-nums;
    font-family: Sora;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    width: 25vw;
    margin-left: 1rem;
    background-color: var(--neutral-100, #F5F5F5);
}

.courseschedule-card p{
    color: var(--black, #262626);
    font-family: Manrope;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    width: 75vw;
    background-color: var(--neutral-100, #F5F5F5);
}