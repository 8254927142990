.banner1-main{
    display: flex;
    height: 70vh;
    border-radius: 25px;
    margin: 2rem;
    padding: 1rem 1rem;
}

.banner1-s1{
    width: 65vw;
    background-color: #501CB7;
    border-radius: 25px 0px 0px 25px;
    cursor: pointer;
}

.banner1-s1 h1{
    color: white;
    margin-top: 1rem;
    background-color: #501CB7;
    background-color: transparent;
    margin-left: 0.03rem;
    padding-left: 1rem;
    color: #FFF;
    font-family: Space Grotesk,sans-serif;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 600;
    border-radius: px 0px 0px 0px;
}

.banner1-s1 p{
    color: var(--caption, rgba(255, 255, 255, 0.80));
font-family: Manrope;
font-size: 20px;
font-style: normal;
font-weight: 400;
background-color: #501CB7;
padding-left: 1.5rem;
padding-right: 1rem;
}

.banner1-s2 img{
 object-fit: cover;
 height: 70vh;
 width: 35vw;
 border-radius: 0px 25px 25px 0px;
}

.banner1-btn{
    display: flex;
    background-color: #501CB7;
    margin: 2rem;
    margin-top: 4rem;
}

.banner1-btn1{
    border: none;
    border-radius: 40px;
    padding: 1rem;
background-color: #FFF;
color: #501CB7;
padding: 1vw 4vw;
}

.banner1-btn2 button{
    border: none;
    background-color: #501CB7;
    color: #FFF;
}

.banner1-btn2{
    background-color: #501CB7;
    border: 1px solid #FFF;
    border-radius: 25px;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem 2rem;
}
.banner1-btn2 img{
    background-color: #501CB7;
    margin-right: 0.6rem;
}

.hb1{
    display: flex;
    background: transparent;
    margin-top: 3.5rem;
}

.hb1 .p1{
    color: #FFF;
font-family: Manrope;
font-size: 42px;
font-style: normal;
font-weight: 600;
}

.hb1 .p2{
    color: rgba(255, 255, 255, 0.80);
font-family: Manrope;
font-size: 16px;
margin: 0;
}

